import BaseComponent from "@/components/base/el/BaseComponent";

export default {
    name: "BaseProducts",
    mixins: [BaseComponent],
    render(createElement) {
        let tag = this.tag;
        let data = this.data_object;
        let children;
        if (this.transform) {
            children = this.$slots.default.concat([
                createElement(
                    "style",
                    {
                        attrs: {
                            scoped: true,
                        },
                    },
                    [this.transform]
                ),
            ]);
        } else children = this.$slots.default;

        return createElement(tag, data, children);
    },
    data() {
        return {
            is_text: true,
            can_drag: true,
            tag: "",
        };
    },
    computed: {
        data_object() {
            let class_array = [{"deepsel-focus": this.is_editing}]
            if (this.component_data.attr && this.component_data.attr.class) {
                let class_value

                if (Array.isArray(this.component_data.attr.class)) {
                    class_value = this.component_data.attr.class.join(" ")
                } else class_value = this.component_data.attr.class

                class_array.push(class_value)
            }

            return {
                class: class_array,
                attrs: {
                    contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                    draggable: false,
                },
                style: this.styling,
                on: {
                    focusout: this.focus_out,
                    // input: this.on_input,
                    mouseenter: this.mouse_enter,
                    mousedown: this.mouse_down,
                },
            };
        },
    },
};
